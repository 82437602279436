import axios from 'axios'
import Cookies from 'js-cookie'

const api = axios.create({
  baseURL: process.env.NEXT_PUBLIC_API_HOST,
})
api.interceptors.request.use((config) => {
  const token = Cookies.get('customer-token')
  if (token) {
    config.headers['Authorization'] = 'Bearer ' + token
  }
  config.params = config.params || {}
  return config
})

export default api
