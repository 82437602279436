import React, { useEffect, useState } from 'react'
import Image from 'next/image'
import Link from 'next/link'
import { AiFillSetting, AiOutlineInfoCircle, AiOutlineClose } from 'react-icons/ai'
import { RiUserFill } from 'react-icons/ri'
import { Layout, Menu, Space, Dropdown, Avatar, Badge, Button, Alert, notification } from 'antd'
import {
  DashboardFilled,
  ShoppingFilled,
  BookFilled,
  BellFilled,
  CloseOutlined,
} from '@ant-design/icons'
import router, { useRouter } from 'next/router'
import { useAdminAuth } from '@/contexts/adminAuth'
import { RetailerProps } from '@/Props/RetailerProps'

import '@/styles/antd.less'
import { Content } from 'antd/lib/layout/layout'
import {
  StyledInputSearch,
  StyledUserText,
  StyledMenuBtn,
  StyledOptionsText,
  StyledNotificationsWrapper,
  StyledHeaderWrapper,
  StyledNotificationsItem,
  StyledSideBar,
  StyledMenuIcon,
  StyledSpace,
  StyledMenu,
} from './styles'
import adminApi from '@/services/adminApi'
import { NotificationProps } from '@/Props/NotificationProps'
import { useFetch } from '@/utils/useFetch'
import { formatDistance } from 'date-fns'
import pt from 'date-fns/locale/pt'
import RetailerGlobalStyle from '@/styles/RetailerGlobalStyle'
import { AdminUserProps } from '@/Props/AdminUserProps'
import Cookies from 'js-cookie'
import * as Sentry from '@sentry/nextjs'

const orders: string[] = []

interface RetailerLayoutProps {
  needAdmin?: boolean
}

/*const languageMenu = (
  <Menu>
    <Menu.Item>
      <StyledOptionsText>Português</StyledOptionsText>
    </Menu.Item>
    <Menu.Item>
      <StyledOptionsText>English</StyledOptionsText>
    </Menu.Item>
  </Menu>
)*/

const { Header, Sider } = Layout

const notifyMe = (notificationId: number, notificationMessage: string) => {
  // Let's check if the browser supports notifications
  if (!('Notification' in window)) {
    alert('This browser does not support desktop notification')
  }

  // Let's check whether notification permissions have alredy been granted
  else if (Notification.permission === 'granted') {
    // If it's okay let's create a notification
    const audio = new Audio('/notification.mp3')
    audio.volume = 0.5
    audio.play()
    new Notification(notificationMessage, { tag: notificationId.toString() })
  }

  // Otherwise, we need to ask the user for permission
  else if (Notification.permission !== 'denied') {
    Notification.requestPermission(function (permission) {
      // If the user accepts, let's create a notification
      if (permission === 'granted') {
        const audio = new Audio('/notification.mp3')
        audio.volume = 0.5
        audio.play()
        new Notification(notificationMessage, { tag: notificationId.toString() })
      }
    })
  }

  // At last, if the user has denied notifications, and you
  // want to be respectful there is no need to bother them any more.
}

interface NotificationDropdownProps {
  user: AdminUserProps
}

const NotificationDropdown = ({ user }: NotificationDropdownProps) => {
  const [notificationKey, setNotificationKey] = useState(0)

  const { data: notifications } = useFetch<NotificationProps[]>(
    `retailers/${user?.retailer_id}/notifications`
  )

  if (notifications) {
    notifications.map((eachNotification) => {
      const findExistOrder = orders.findIndex(
        (order) => order == eachNotification.order_id.toString()
      )

      if (
        eachNotification.message.includes('Nova encomenda') &&
        !eachNotification.is_retailer_read &&
        findExistOrder === -1
      ) {
        orders.push(eachNotification.order_id.toString())
        Cookies.set('closedNotificationPopup', 'false')
        if (notification) {
          notification.destroy()
        }
      }
    })
  }

  const handleShowNotifications = async (isVisible: boolean) => {
    if (isVisible) {
      await Promise.all(
        unreadedNotifications.map(async (notification) => {
          await adminApi.put(`notifications/${notification.id}`, {
            is_retailer_read: true,
          })
        })
      )
    }
  }

  const notificationsMenu = () => {
    return (
      <StyledNotificationsWrapper direction="vertical" size={0}>
        {notifications?.slice(0, 5).map((notification, key) => {
          return (
            <a
              href={notification.order_id ? `/orders/${notification.order_id}` : '#'}
              key={key}
              style={{ textDecoration: 'none' }}
            >
              <StyledNotificationsItem className="notification-item" direction="vertical" size={0}>
                <p>{notification.message}</p>
                <span>
                  {formatDistance(new Date(notification.created_at), new Date(), {
                    includeSeconds: true,
                    locale: pt,
                  }).replace('aproximadamente', '')}
                </span>
              </StyledNotificationsItem>
            </a>
          )
        })}
        <Link href="/settings/notifications">
          <a> Ver todas </a>
        </Link>
      </StyledNotificationsWrapper>
    )
  }

  if (!notifications) {
    return <></>
  }

  if (notifications.length > 0) {
    let haveNotifications = false
    notifications.map((notification) => {
      if (!notification.is_retailer_notified) {
        haveNotifications = true
        adminApi.put(`notifications/${notification.id}`, {
          is_retailer_notified: true,
        })
      }
    })
    if (haveNotifications) {
      notifyMe(
        notifications[notifications.length - 1].id,
        notifications[notifications.length - 1].message
      )
    }
  }

  const unreadedNotifications = notifications.filter(
    (notification) => notification.is_retailer_read == false
  )

  const handleNotificationClose = () => {
    Cookies.set('closedNotificationPopup', 'true')
  }

  if (orders.length > 0) {
    if (
      notification &&
      notificationKey !== orders.length &&
      Cookies.get('closedNotificationPopup') !== 'true'
    ) {
      const btn = (
        <Button
          onClick={async () => {
            Cookies.set('closedNotificationPopup', 'true')
            notification.destroy()
            await Promise.all(
              unreadedNotifications.map(async (notification) => {
                await adminApi.put(`notifications/${notification.id}`, {
                  is_retailer_read: true,
                })
              })
            )
            router.push('/orders')
          }}
        >
          <a>Ver encomendas</a>
        </Button>
      )

      notification.open({
        className: 'custom-notification-popup',
        placement: 'topRight',
        top: 68,
        duration: 0,
        onClose: handleNotificationClose,
        key: orders.length.toString(),
        message: `Tem ${orders.length} ${orders.length > 1 ? 'novas' : 'nova'}  encomenda`,
        description: `Você tem ${orders.length} ${
          orders.length > 1 ? 'novas' : 'nova'
        } encomenda por processar.`,
        btn,
        icon: <AiOutlineInfoCircle style={{ color: '#108ee9' }} />,
      })
      Cookies.set('closedNotificationPopup', 'false')
      setNotificationKey(orders.length)
    }
  }

  return (
    <Dropdown
      overlay={notificationsMenu}
      onVisibleChange={(visible) => handleShowNotifications(visible)}
    >
      <Badge
        count={unreadedNotifications.length}
        style={{ backgroundColor: '#F8F5F8', color: '#7B2E6F' }}
      >
        <BellFilled size={20} style={{ fontSize: '20px', color: '#000' }} />
      </Badge>
    </Dropdown>
  )
}

const RetailerLayout: React.FC<RetailerLayoutProps> = ({ needAdmin, children }) => {
  const router = useRouter()
  const { isAuthenticated, user, logout } = useAdminAuth()
  const [isLoading, setIsLoading] = useState(true)
  const [retailerName, setRetailerName] = useState('')
  const [retailerId, setRetailerId] = useState('')
  const [openKeys, setOpenKeys] = useState([])
  const [openMenu, setOpenMenu] = useState(false)

  useEffect(() => {
    if (!isAuthenticated || !user) {
      router.replace('/')
      return
    }
    if (user?.is_admin) {
      logout()
    }
    if (isAuthenticated && user) {
      try {
        adminApi.get<RetailerProps>('/retailers/me').then((response) => {
          if (response.data) {
            if (router.pathname && router.pathname != '/edit' && !response.data.horary_type) {
              notification.close('horary-need')
              notification.warning({
                message: 'Horário obrigatório',
                description:
                  'Para que os teus artigos aparecem na loja precisamos que inseridas o teu horário de funcionamento',
                key: 'horary-need',
              })
              router.replace('/edit')
            }
            setRetailerName(response.data.name)
            setRetailerId(`${response.data.id}`)
          }
        })
      } catch (error) {
        Sentry.captureException(error)
      }
    }
    if (router.pathname && router.pathname == '/products') {
      setOpenKeys(['products'])
    }
    setIsLoading(false)
  }, [isAuthenticated, logout, needAdmin, router, user])

  const optionsMenu = (
    <StyledMenu>
      <Menu.Item>
        <Link href={`/settings/users/edit/${user?.id}`}>
          <a>
            <StyledOptionsText>Ver perfil</StyledOptionsText>
          </a>
        </Link>
      </Menu.Item>
      <Menu.Item>
        <Link href="/edit">
          <a>
            <StyledOptionsText>A minha loja</StyledOptionsText>
          </a>
        </Link>
      </Menu.Item>
      <Menu.Item>
        <Link href="/settings/users">
          <a>
            <StyledOptionsText>Utilizadores</StyledOptionsText>
          </a>
        </Link>
      </Menu.Item>
      <Menu.Item>
        <Link href="/settings/notifications">
          <a>
            <StyledOptionsText>Notificações</StyledOptionsText>
          </a>
        </Link>
      </Menu.Item>
      <Menu.Item>
        <StyledMenuBtn type="link" onClick={() => logout()} danger>
          Terminar sessão
        </StyledMenuBtn>
      </Menu.Item>
    </StyledMenu>
  )

  if (isLoading) {
    return <></>
  }

  const OpenMenuMobile = () => {
    setOpenMenu(!openMenu)
  }

  return (
    <>
      <RetailerGlobalStyle />
      <Layout style={{ minHeight: '100%' }}>
        <Header
          style={{
            boxShadow: '0px 3px 5px -1px rgba(67, 4, 59, 0.06)',
            zIndex: 9,
            position: 'fixed',
            width: '100%',
          }}
        >
          <StyledHeaderWrapper align="center">
            <Link href="/">
              <a className="principal-logo">
                <img
                  src="/assets/images/swig-logo-purple.svg"
                  alt="SWIG"
                  width="94"
                  height="32"
                  className="logo-admin-page"
                />
              </a>
            </Link>
            <Button type="link" onClick={() => OpenMenuMobile()} className="open-button-admin">
              <StyledMenuIcon />
            </Button>
            <Space direction="horizontal" size={28} className="search-input">
              {/*  <StyledInputSearch
                placeholder="Pesquisar"
                size="middle"
                bordered={false}
                allowClear
              />
              /*}
              {/*<StyledLanguageText>
              PT
              <CaretDownOutlined />
            </StyledLanguageText>*/}
              <Dropdown overlay={optionsMenu}>
                <a className="ant-dropdown-link">
                  <Avatar
                    style={{
                      backgroundColor: '#F8F5F8',
                      color: '#7B2E6F',
                      alignItems: 'center',
                      display: 'inline-flex',
                      justifyContent: 'center',
                    }}
                    icon={<RiUserFill />}
                  ></Avatar>
                  <StyledUserText>{user?.name}</StyledUserText>
                </a>
              </Dropdown>
              <NotificationDropdown user={user} />
            </Space>
          </StyledHeaderWrapper>
        </Header>
        <Layout>
          <Sider className={openMenu ? 'sidebar-retailer active' : 'sidebar-retailer '}>
            <StyledSideBar>
              <StyledSpace>
                <Space
                  direction="horizontal"
                  size={0}
                  className="sidebar-header"
                  style={{ display: 'flex', flexDirection: 'row', width: '100%' }}
                >
                  <a>
                    {' '}
                    <img
                      src="/assets/images/swig-logo-purple.svg"
                      alt="SWIG"
                      className="logo-admin-page-sidebar"
                    />{' '}
                  </a>
                  <Button type="link" onClick={() => OpenMenuMobile()} className="close-button">
                    <CloseOutlined />
                  </Button>
                </Space>
                <Space className="search-space-mobile">
                  {/* <StyledInputSearch
                    placeholder="Pesquisar"
                    size="middle"
                    bordered={false}
                    allowClear
                  /> */}
                </Space>
                <Menu
                  mode="inline"
                  defaultSelectedKeys={[router.pathname]}
                  defaultOpenKeys={openKeys}
                  className="menu-sidebar"
                >
                  <Menu.Item key="/" icon={<DashboardFilled />}>
                    <Link href="/">
                      <a> Início </a>
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="/orders" icon={<ShoppingFilled />} style={{ fontSize: '16' }}>
                    <Link href="/orders">
                      <a> Encomendas </a>
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="/products" icon={<BookFilled />} style={{ fontSize: '16' }}>
                    <Link href="/products">
                      <a> Artigos </a>
                    </Link>
                  </Menu.Item>
                </Menu>
                <Menu
                  mode="inline"
                  defaultSelectedKeys={[router.pathname]}
                  defaultOpenKeys={openKeys}
                  className="menu-sidebar-bottom"
                >
                  <Menu.Item
                    key="/settings"
                    icon={<AiFillSetting />}
                    style={{ margin: 0, padding: 0 }}
                  >
                    <Link href="/settings">
                      <a> Definições </a>
                    </Link>
                  </Menu.Item>
                </Menu>
              </StyledSpace>
            </StyledSideBar>
          </Sider>
          <Content style={{ marginTop: 64 }}>{isAuthenticated ? children : ''}</Content>
        </Layout>
      </Layout>
    </>
  )
}

export default RetailerLayout
