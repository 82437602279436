import { createGlobalStyle } from 'styled-components'

export default createGlobalStyle`

  .ant-breadcrumb a{
    font-family: Lato;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: #6B6B6B;
  }

  .ant-breadcrumb-separator{
    color: #6B6B6B;
  }
  .ant-table-wrapper{
    margin-top:0;
  }

  .ant-form-vertical .ant-form-item-label > label{
    font-family: Lato;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
  }

  form .ant-input::placeholder{
    font-family: Lato;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
  }

  .ant-card-head-title{
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
  }

  .ant-card-body{
    padding: 0 30px 30px 30px;
  }

  .ant-card-head{
    min-height: unset;
  }

  .ant-select-item-option-content {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    color: #6B6B6B;
  }

  .ant-input-search > .ant-input-group > .ant-input-group-addon:last-child .ant-input-search-button{
    border-radius: 0;
  }

  .ant-input-search .ant-input-affix-wrapper{
    border-radius: 0 !important;
  }
  .ant-input-search-small .ant-input-search-button{
    background-color: white;
    height: 32px;
    border: 0.90px solid #D9D9D9;
    border-left: 0;
  }

  .ant-select:not(.ant-select-customize-input) .ant-select-selector{
    border-radius: 0;
  }
  .ant-select{
    font-family: Lato;
  }
  .ant-breadcrumb + .ant-page-header-heading{
    margin-top: 14px;
  }
  .ant-input-group{
    padding-left: 6px;
  }
  .ant-btn .anticon{
    color: #393939;
  }
  .ant-select-arrow .anticon{
    color: #393939;
  }

  .ant-page-header.has-breadcrumb{
    padding-top: 0px;
    padding-bottom: 22px;
  }
  .ant-table-title{
    padding:0;
    padding-top: 16px;
    padding-bottom: 16px;
  }

  .ant-input {
    border-radius: 0;
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    color: #393939;
  }
  .ant-tag {
    padding: 4px 8px;
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
  }

  .view-order .ant-page-header.has-breadcrumb {
    padding-bottom: 16px;
  }

  .ant-modal-body {
    padding-top: 0;
  }

  .ant-modal-footer {
    padding: 0 24px 24px;
  }

  .ant-modal-footer .ant-btn:first-child {
    border: none;
  }

  .table-page .ant-select-selection-placeholder, .table-page .ant-select-selection-item {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
  }

  .ant-modal-confirm-body .ant-modal-confirm-content {
    font-family: Lato;
  }

  .ant-checkbox-inner, .ant-radio-inner {
    border-color: #E2E2E2;
  }

  .ant-checkbox + span {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #6B6B6B;
  }

  .ant-input-lg {
    padding: 14.5px 12px;
  }

  .ant-input-number-lg input {
    font-family: Lato;
  }

  .ant-input-group-addon .ant-btn {
    background: #ffffff;
    border-color: #e2e2e2;
    border-left: unset;
  }

  .ant-pagination-item{
    font-family: Lato;
  }

  .ant-select-dropdown{
    border-radius: 0;
    border-color: #E2E2E2;
  }

  .ant-table-title{
    padding-bottom: 0 !important;
  }

  .ant-modal-confirm-btns .ant-btn {
    box-shadow: unset;
  }

  .ant-input{
    color: rgba(57, 57, 57, 1);
  }

  span.ant-radio + span {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #6B6B6B;
  }

  .search-space-mobile {
    display: none;
  }

  @media (max-width: 800px) {
    .sidebar-retailer.active {
      z-index: 99;
      max-width: unset!important;
      background: rgba(0, 0, 0, 0.3);
    }

    .sidebar-retailer {
      background: transparent;
      transform: translateX(0px);
      z-index: -1;
      position: fixed;
    }

    .sidebar-retailer .ant-layout-sider-children {
      position: fixed;
      background: #ffffff;
      max-width: 300px;
      z-index: 99;
      transition: all 0.5s;
      left: -100%;
      width: 100%;
      overflow-y: scroll;
    }

    .sidebar-retailer.active .ant-layout-sider-children {
      left: 0;
    }

    .search-space-mobile {
      display: flex;
      padding: 30px 24px 0 24px;
      width: 100%;
    }

    .sidebar-header {
      flex: auto;
      padding-top: 30px;
      padding-left: 24px;
      padding-right: 24px;
      align-items: flex-start;
    }

    .sidebar-header > .ant-space-item {
      flex: 1;
    }

    .sidebar-header > .ant-space-item a {
      display: block;
      width: 100%;
      text-align: center;
    }

    .sidebar-header > .ant-space-item .close-button {
      margin-left: auto;
      padding: 0;
      height: auto;
    }

    .sidebar-header > .ant-space-item a .logo-admin-page-sidebar {
      width: auto!important;
      height: auto!important;
      margin: 0!important;
    }

    .sidebar-header > .ant-space-item:last-child {
      text-align: right;
    }

    .search-space-mobile .ant-input-group-wrapper.ant-input-search {
      display: flex;
      width: 100%;
      height: auto;
    }

    .search-space-mobile > .ant-space-item {
      width: 100%;
    }

    .sidebar-retailer .ant-layout-sider-children .menu-sidebar {
      margin-top: 28px;
    }

    .sidebar-retailer .ant-layout-sider-children .menu-sidebar-bottom {
      margin-bottom: 28px;
    }

    .search-space-mobile > .ant-space-item .ant-input-affix-wrapper {
      padding: 14px 12px;
    }

    .search-space-mobile > .ant-space-item .ant-input-affix-wrapper input {
      line-height: 20px;
    }

    .search-space-mobile > .ant-space-item .ant-input-search-button {
      height: 48px;
    }
  }

  .ant-card-body .ant-form-item {
    margin-bottom: 16px;
  }

  .ant-card-body .ant-form-item:last-of-type {
    margin-bottom: 0;
  }

  .url-input .ant-input-group-addon {
    font-family: Lato;
    border: unset;
  }
`
